
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: rgb(241, 241, 227);
}

.App-link {
  color: #61dafb;
}
/*@font-face {
  font-family: 'MyFont';
  src: local('MyFont'), url("./fonts/AmaticSC-Bold.ttf") format("truetype"); 
}*/
p {
  font-size: 1.2vw;
}
#subtitle {
  font-size: 2rem;
}


#background {
  position: absolute;
  width: 100%;
  background: linear-gradient(320deg,rgb(31, 30, 25),rgb(29, 28, 21),rgb(65, 64, 45));
  background-size: 180% 180%;
  animation: gradient-animation 18s ease infinite;

}

#tabpanel2 {
  background: linear-gradient(320deg,rgb(31, 30, 25),rgb(29, 28, 21),rgb(65, 64, 45));
  width: 100%;
  height: 90%;
  margin-top: 5vh;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

#overlay {
  position: absolute;
  z-index: 100;
  margin: 0px;
  margin-top: 0px;
  margin-bottom: 0px;

}

.product {
  margin: 10px;
  width: 150px;
  height: 250px;
  border: 1px solid black;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  text-align: center;
  
}

.productimg  {
  width: 130px;
  height: 130px;
}

@media only screen and (max-width: 900px) {
  p {
    font-size: 2.6vw;
  }
  #subtitle {
    font-size: 1em;
  }
  .product {
    width: 125px;
    height: 225px;

  }
  .productimg  {
    width: 115px;
    height: 115px;
  }
}




